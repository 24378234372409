<template>
  <FormStructure :title="'Create a new password'">
    <template v-slot:form-content>
      <div class="relative">
        <InputText
          :input-type="showPassword ? 'text' : 'password'"
          :label="'New Password'"
          :hasIcon="true"
          @input="setPassword"
        >
          <template v-slot:append-icon>
            <ShowPasswordIcon
              :show-password="showPassword"
              @toogle-password="showPassword = !showPassword"
            />
          </template>
        </InputText>
      </div>

      <div class="relative">
        <InputText
          :input-type="showConfirm ? 'text' : 'password'"
          :label="'Confirm Password'"
          :hasIcon="true"
          @input="setConfirm"
        >
          <template v-slot:append-icon>
            <ShowPasswordIcon
              :show-password="showConfirm"
              @toogle-password="showConfirm = !showConfirm"
            />
          </template>
        </InputText>
      </div>

      <div>
        <Button :btn-text="'Submit'" @click="createPass()" />
      </div>

      <BottomLink :link-route="'/login'" :text="'Back'" />
    </template>
  </FormStructure>
</template>

<script>
import Button from "/src/components/auth/Button.vue";
import InputText from "/src/components/auth/InputText.vue";
import ShowPasswordIcon from "/src/components/auth/ShowPasswordIcon.vue";
import BottomLink from "/src/components/auth/BottomLink.vue";
import FormStructure from "/src/components/auth/FormStructure.vue";
import { mapActions } from "vuex";

export default {
  name: "VerifyPasswordForm",
  components: {
    Button,
    InputText,
    ShowPasswordIcon,
    BottomLink,
    FormStructure,
  },
  data: () => {
    return {
      showPassword: false,
      showConfirm: false,
      password: "",
      confirm: "",
    };
  },
  methods: {
    ...mapActions({ resetPass: "auth/resetPass" }),
    setPassword(value) {
      this.password = value;
    },
    setConfirm(value) {
      this.confirm = value;
    },
    createPass() {
      if (this.confirm == "" || this.password == "") {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "All fields are required",
          uptime: 3000,
          hasError: true,
        });
      } else if (
        /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9!@#$&()-`.+,/"]+$/.test(
          this.password
        ) == false
      ) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "Password must contain upper case characters, lower case characters and digits",
          uptime: 3000,
          hasError: true,
        });
      } else if (this.confirm != this.password) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "Passwords does not match",
          uptime: 3000,
          hasError: true,
        });
      } else {
        this.resetPass(this.password);
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "Password successfully reset",
          uptime: 3000,
        });
      }
    },
  },
};
</script>

<style>
</style>