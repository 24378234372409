<template>
  <div
    class="my-icon inset-y-0 right-0 pr-3 flex items-center my-auto justify-center"
  >
    <span @click="$emit('toogle-password')">
      <i
        class="text-lg text-gray-400 fa"
        :class="[showPassword ? 'fa-eye' : 'fa-eye-slash']"
        aria-hidden="true"
      ></i>
    </span>
  </div>
</template>

<script >
export default {
  name: "ShowPasswordIcon",
  props: {
    showPassword: Boolean,
  },
};
</script>

<style lang="scss">
.my-icon {
  color: #cccccc;
  position: relative;
  left: 4.92%;
}
</style>